import React, { FunctionComponent, useContext, useEffect } from "react";
import SliderBlock from "../SliderBlock";
import Section from "../Section/index";
import { event11 } from "../../analytics/event11";
import { LocalizedContext } from "../../services/LocalizedContextService";

import "swiper/scss";

export interface ProductSliderInterface {
  title?: string;
  subTitle?: string;
  bgColor?: {
    title: string;
  };
  collections: any;
}

const CollectionSlider: FunctionComponent<ProductSliderInterface> = props => {
  const { title, subTitle, bgColor, collections } = props;
  const { sanitySiteSettings } = useContext(LocalizedContext);
  useEffect(() => {
    collections && event11(collections, sanitySiteSettings?.brandName, "Collection Slider");
  }, [collections, sanitySiteSettings?.brandName]);

  return (
    <Section sectionClassName="c-collection-slider" theme={bgColor?.title} title={title} description={subTitle}>
      {collections && (
        <SliderBlock data={collections as ProductSliderInterface["collections"]} type="collection-slider" />
      )}
    </Section>
  );
};

export default CollectionSlider;
